import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton';
import BugIcon from '@material-ui/icons/BugReportRounded';
import UserSettingsContext from '../UserSettings/UserSettingsContext.js';
import { simplifyUserSettings } from '../../helpers/feedback.js';

const useStyles = makeStyles(theme => ({
  button: {
    color: '#999',
    '&:hover': {
      color: '#fff'
    }
  }
}));

const BugButton = () => {
  const classes = useStyles();
  const settings = useContext(UserSettingsContext);

  const handleClick = () => {
    // pass in extra info to getsitecontrol
    window.gsc('params', {
      useragent: navigator.userAgent,
      vendor: navigator.vendor,
      platform: navigator.platform,
      userSettings: simplifyUserSettings(settings)
    })
    // show the widget
    window.gsc('show', 53203);
  };
  return (
    <IconButton className={classes.button} onClick={handleClick}>
      <BugIcon fontSize='large' />
    </IconButton>
  );
};

export default BugButton;
